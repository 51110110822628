import { ExerciseStatus } from "../../constants/exercises";
import { getMinutesAndSeconds } from "../common/exerciseHelper";
import { MmExercises, MmLevel, MmState, MmTheme, MmUserScore } from "./mmSlice";

export interface MmCurrent {
  totalExercise: number
  currentExercise: number
}

export function isReadyToStart(status: ExerciseStatus, exercises?: MmExercises): boolean {
  return status === 'start' && exercises !== undefined
}

export const getCurrent = (exercises: MmExercises | undefined, userScores: MmUserScore[]): MmCurrent => {
  const totalExercise: number = exercises?.count || 0
  let currentExercise: number = userScores.length
  if (currentExercise === 0) {
    currentExercise = 1
  } else {
    const { end } = userScores[userScores.length - 1]
    if (end !== undefined) {
      currentExercise += 1
    }
  }
  return { totalExercise, currentExercise }
}

export interface MmThemeAndLevel {
  theme?: MmTheme
  level?: MmLevel
}

export const getThemeAndLevel = (themes: MmTheme[], selectedTheme?: string, selectedLevel?: number): MmThemeAndLevel => {
  const theme = selectedTheme !== undefined ? themes.find(theme => theme.slug === selectedTheme) : undefined
  const level = theme !== undefined && selectedLevel !== undefined ? theme.levels[selectedLevel] : undefined
  return { theme, level }
}

interface SelectedThemeAndLevel {
  theme: string
  level: number
}

export const getLastSelectedThemeAndLevel = (userScores: MmUserScore[]): SelectedThemeAndLevel | null  => {
  if (userScores.length) {
    const lastScore = userScores[userScores.length - 1]
    const { theme, level } = lastScore
    return { theme, level }
  }
  return null
}

export const startExercise = (theme: string, level: number, state: MmState) => {
  const { userScores } = state
  const scoreLength = userScores.length
  const start = Date.now()
  if (scoreLength === 0) {
    userScores.push({ theme, level, start })
    return
  }
  const scoreIndex = scoreLength - 1
  const userScore = userScores[scoreIndex]
  if (userScore.end !== undefined) {
    userScores.push({ theme, level, start })
  }
}

export const endExercise = (finished: boolean, attempts: number, state: MmState) => {
  const { userScores } = state
  const scoreLength = userScores.length
  if (scoreLength > 0) {
    const lastScore = userScores[scoreLength - 1]
    if (lastScore.end === undefined) {
      lastScore.end = Date.now()
      lastScore.attempts = attempts
      lastScore.finished = finished
    }
  }
}

export const moveNext = (state: MmState, repeat?: boolean) => {
  const { status, exercises, userScores } = state
  if (status === 'start') {
    if (exercises !== undefined) {
      state.startTime = Date.now()
      state.status = 'in-progress'
    }
    return
  }
  if (status === 'completed') {
    if (!repeat) {
      quitExercise(state)
    } else {
      state.status = 'in-progress'
    }
    return  
  }
  if (status === 'check-point') {
    state.status = 'in-progress'
  }
  if (status === 'in-progress') {
    if (exercises) {
      const lastScore = userScores[userScores.length - 1]
      if (userScores.length >= exercises.count) {
        if (lastScore.end !== undefined) {
          state.endTime = Date.now()
          state.status = 'completed'
          return
        }
      } else {
        if (lastScore.end !== undefined) {
          state.endTime = Date.now()
          state.status = 'check-point'
          return
        }
      }
    }
    state.endTime = Date.now()
  }
}

export const quitExercise = (state: MmState) => {
  state.status = 'start'
  state.id = undefined
  state.exercises = undefined
  state.userScores = []
  state.startTime = undefined
  state.endTime = undefined
}

export interface MmResult {
  theme?: string
  level: number
  time: string
  attempts?: number
}

export const getResults = (status: ExerciseStatus, themes: MmTheme[], userScores: MmUserScore[]): MmResult[] => {
  let results: MmResult[] = []
  if (status === 'completed' || status === 'check-point') {
    results = userScores.map(userScore => {
      const { mins, seconds } = getMinutesAndSeconds(userScore.start, userScore.end)
      const theme = themes.find(theme => theme.slug === userScore.theme)
      return {
        theme: theme?.name,
        level: userScore.level + 1,
        time: `${mins}:${seconds.toString().padStart(2, '0')}`,
        attempts: userScore.attempts
      }
    })
    results.reverse()
  }
  return results;
}