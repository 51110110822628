import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExerciseStatus } from "../../constants/exercises"
import { BaseExercise, BaseUserAnswer, moveNext, quitExercise } from "../common/exerciseHelper"
import { updateAnswer } from "./ftiHelper"

export interface FtiExerciseSet {
  question: string
  answers: string[]
  correctAnswer: number
}

export type FtiFlipDirection = 'veritical' | 'horizontal'

export interface FtiExercise extends BaseExercise<FtiExerciseSet> {
  kind: FtiFlipDirection
}

export interface FtiUserAnswer extends BaseUserAnswer<number> {}

export interface FtiState {
  status: ExerciseStatus
  id?: string
  exercises: FtiExercise[]
  userAnswers: FtiUserAnswer[]
  startTime?: number
  endTime?: number
}

const initialState: FtiState = {
  status: 'start',
  exercises: [],
  userAnswers: []
}

interface FtiExercisesPayload {
  id: string
  exercises: FtiExercise[]
}

export const ftiSlice = createSlice({
  name: 'flipTheImage',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<FtiExercisesPayload>) => {
      state.exercises = action.payload.exercises
      state.id = action.payload.id
    },
    next: (state, action: PayloadAction<boolean|undefined>) => {
      moveNext(state, action.payload)
    },
    answer: (state, action: PayloadAction<number>) => {
      updateAnswer(action.payload, state)
      moveNext(state)
    },
    quit: (state) => {
      quitExercise(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction<string|undefined>('flipTheImage/requestQuit')

export const { installExercises, next, answer, quit, reset } = ftiSlice.actions

export default ftiSlice.reducer
