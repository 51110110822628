import { mapValues, sample } from "lodash"
import { APIMEDIA_HOST } from "../../conf/hosts"
import { AFFIRMATION, CategoryType, ExerciseStatus } from "../../constants/exercises"
import { IButton, pages } from "../../constants/pages"
import { FtiState } from "../flipTheImage/ftiSlice"
import { NsState } from "../numberSymbol/nsSlice"
import { UserDetails, videoKey } from "../user/userSlice"
import { WtState } from "../wordTheme/wtSlice"

export function isReadyToStart<Exercise>(status: ExerciseStatus, exercises: Exercise[]): boolean {
  return status === 'start' && exercises.length > 0 
}

export interface Current {
  totalExercise: number
  currentExercise: number
  currentSet: number
  currentExerciseIndex: number
}

export interface BaseExercise<SetType> {
  sets: SetType[]
}

export interface BaseUserAnswer<SetType> {
  sets: SetType[]
  index: number
}

export function getCurrent<Exercise extends BaseExercise<any>, UserAnswer extends BaseUserAnswer<any>>(exercises: Exercise[], userAnswers: UserAnswer[]): Current {
  const totalExercise: number = exercises.length
  let currentExercise: number = userAnswers.length
  let currentExerciseIndex: number = 0
  let currentSet: number = 1
  if (currentExercise === 0) {
    currentExercise = 1
    currentExerciseIndex = 0
  } else {
    const { sets, index } = userAnswers[userAnswers.length - 1]
    if (sets.length < exercises[index].sets.length) {
      currentSet = sets.length + 1
    } else {
      currentExercise += 1
    }
    currentExerciseIndex = index
  }
  return { totalExercise, currentExercise, currentSet, currentExerciseIndex }
}

export function getCurrentSet<Exercise extends BaseExercise<any>>(exercises: Exercise[], currentExerciseIndex: number, currentSet: number): any {
  const exercise = exercises[currentExerciseIndex]
  if (exercise !== undefined) {
    return exercise.sets[currentSet - 1] || {}
  }
  return {}
} 

export function getCurrentExercise<Exercise extends BaseExercise<any>>(exercises: Exercise[], currentExerciseIndex: number): Exercise {
  return exercises[currentExerciseIndex] || {}
}

type ExerciseState = FtiState | NsState | WtState

export function baseUpdateAnswer<AnswerType>(
  answer: AnswerType, 
  state: ExerciseState, 
  addSetAnswer: (answer: AnswerType, index: number) => void): void
{
  const { userAnswers, exercises } = state
  const answersLength = userAnswers.length
  const answerIndex = answersLength - 1
  const userAnswer = userAnswers[answerIndex]
  if (userAnswer.sets.length < exercises[userAnswer.index].sets.length) {
    addSetAnswer(answer, answerIndex)
  }
}

export const moveNext = (state: ExerciseState, repeat?: boolean): void => {
  const { status, exercises, userAnswers } = state
  const lastUserAnswer = userAnswers[userAnswers.length - 1]
  if (status === 'start') {
    if (exercises.length) {
      state.startTime = Date.now()
      state.status = 'in-progress'
      userAnswers.push({ sets: [], index: 0 }) 
    }
    return
  }
  if (status === 'completed') {
    if (!repeat) {
      quitExercise(state)
    } else {
      state.status = 'in-progress'
      userAnswers.push({ sets: [], index: lastUserAnswer.index }) 
    }
    return
  }
  if (status === 'check-point') {
    state.status = 'in-progress'
    const nextIndex = repeat ? lastUserAnswer.index : lastUserAnswer.index + 1
    userAnswers.push({ sets: [], index: nextIndex }) 
}
  if (status === 'in-progress') {
    if (lastUserAnswer.index === exercises.length - 1) {
      if (lastUserAnswer.sets.length === exercises[lastUserAnswer.index].sets.length) {
        state.endTime = Date.now()
        state.status = 'completed'
        return
      }
    } else {
      if (lastUserAnswer.sets.length === exercises[lastUserAnswer.index].sets.length) {
        state.endTime = Date.now()
        state.status = 'check-point'
        return
      }
    }
    state.endTime = Date.now()    
  }
}

export const quitExercise = (state: ExerciseState): void => {
  state.status = 'start'
  state.id = undefined
  state.exercises = []
  state.userAnswers = []
  state.startTime = undefined
  state.endTime = undefined
}

export const getTotalMinutes = (startTime?: number, endTime?: number): number => {
  if (startTime !== undefined && endTime !== undefined) {
    return Math.round((endTime - startTime) / 60000)
  }
  return 0
}

export interface MinsSeconds {
  mins: number
  seconds: number
}

export const getMinutesAndSeconds = (startTime?: number, endTime?: number): MinsSeconds => {
  if (startTime !== undefined && endTime !== undefined) {
    const totalSeconds =  Math.round((endTime - startTime) / 1000)
    const mins = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    return { mins, seconds }
  }
  return { mins: 0, seconds: 0 }
}

interface IVideoUrls {
  videos: string[]
  poster: string
}

export const getVideoUrls = (slug: videoKey, userDetails?: UserDetails): IVideoUrls|undefined => {
  if (userDetails && userDetails.videos && userDetails.videos[slug]) {
    const target = userDetails.videos[slug]
    const videos = [target.mp4, target.webm].map(url => `${APIMEDIA_HOST}${url}`)
    const poster = `${APIMEDIA_HOST}${target.poster}`
    return { videos, poster }
  }
}

export const setCommonMaxHeightButtons = (element: Element|null) => {
  if (element) {
    setTimeout(() => {
      const buttons = Array.from(element.getElementsByTagName('button'))
      const maxHeight = buttons.reduce((maxHeight, button) => 
        button.offsetHeight > maxHeight ? button.offsetHeight : maxHeight
      , 0)
      buttons.forEach(button => button.style.height = `${maxHeight}px`)  
    }, 500)
  }
}

export const getTotalDuration = (durations: number[]): number => durations.reduce((acc, duration) => acc + duration, 0)

export interface IEntry {
  startDate: number
  duration: number
}

export const getCognitiveFooterButtons = (
  status: ExerciseStatus,
  exerciseName: string,
  isReadyToStart: boolean,
  backTo: (route: string) => void,
  handleNext: () => void,
  handleRepeat: () => void,
  repeatLabel?: string,
  continueLabel? : string,
): IButton[] => { 
  const returnToMenuLabel = 'Return to the Cognitive Exercises Menu'
  const goToCenteredBreathing = 'Go to Centered Breathing'
  const loading = status === 'start' && !isReadyToStart
  const nextLabel: string = status === 'completed' ? returnToMenuLabel
    : status === 'check-point' ? continueLabel || `Complete more ${exerciseName} Exercises` : 'Start'
  const footerButtons: IButton[] = [{ label: nextLabel, action: handleNext, buttonType: "primary", loading }]

  if (status === 'check-point') {
    footerButtons.push(
      { label: goToCenteredBreathing, action: () => backTo(pages.centeredBreathing.route), buttonType: "success"},
      { label: returnToMenuLabel, action: () => backTo(pages.cognitiveExercises.route), buttonType: "primary"}
    )
  }

  if (status === 'completed') {
    footerButtons.unshift(
      { label: goToCenteredBreathing, action: () => backTo(pages.centeredBreathing.route), buttonType: "success"},
    )
  }

  if (status === 'check-point' || status === 'completed') {
    footerButtons.unshift({ label: repeatLabel || 'Repeat the same exercise', action: handleRepeat, buttonType: "default" })
  }
  return footerButtons
}

export const getCenteredBreathingText = (status: ExerciseStatus): string|undefined =>
  (status === 'completed' || status === 'check-point') ? 'When you have completed your exercise session, finish with a centered breathing exercise for additional stress relief' : undefined

type Size = 'xs' | 'sm' | 'md' | 'lg'

export type ICardCols = {
  [key in Size]?: number
}

const getCardCol = (col: number, numCards: number): number => {
  const maxTilesPerRow = 12 / col
  if (12 % col === 0 && numCards % maxTilesPerRow === 0) {
    return col
  } else {
    return getCardCol(col + 1, numCards)
  }
}
  
export const getCardCols = (numCards: number, cardColsMin: ICardCols): ICardCols => {
  return mapValues(cardColsMin, (col) => {
    return getCardCol(col || 1, numCards)
  })
}

export const getAffirmation = (category: CategoryType): string => {
  return sample(AFFIRMATION[category]) as string
}
  