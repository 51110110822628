import { Card, Button } from 'antd'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import { getChoiceLabel } from '../ftiHelper'
import classnames from 'classnames'
import styled from 'styled-components'
import { FtiFlipDirection } from '../ftiSlice'
import FooterButtons from '../../common/components/FooterButtons'
import { APIMEDIA_HOST } from '../../../conf/hosts'

export interface IFtiProgressProps {
  status: ExerciseStatus
  kind: FtiFlipDirection
  currentExercise: number
  currentSet: number
  questionSrc: string
  answerSrcs: string[]
  answer?: number
  pickAnswer: (choice: number) => void
  next: () => void
}

const Question = styled(Card)`
  box-shadow: 0 0 7px rgba(0,0,0,0.5);
`

const Choice = styled(Button)`
  height: auto;

  &.selected {
    border: solid 3px #1890ff;
  }
`

const FtiProgress = ({ status, kind, currentExercise, currentSet, questionSrc, answerSrcs, answer, pickAnswer, next }: IFtiProgressProps) => {

  if (status !== 'in-progress') { return null }

  const answerCols = answerSrcs.map((answerSrc, index) => {
    const buttonClasses = classnames('pt-3', { selected: answer === index })
    return (
      <Col xs={10} sm={8} md={4} key={`answer-${index}`} className="my-1">
        <Choice className={buttonClasses} block onClick={() => pickAnswer(index)}>
          <Image draggable={false} src={`${APIMEDIA_HOST}${answerSrc}`} fluid height="115px" />
          <h6>{getChoiceLabel(index)}</h6>
        </Choice>
      </Col>
    )
  })

  const veriticalInfo = (
    <p>Below are a series of images presented in separate rows. Look at the images carefully. Your task is to try to flip the top row image <strong>vertically</strong> (upside down). Then, choose among the three possible matches in the bottom rows (labeled “A, B, and C”).</p>
  )

  const horizontalInfo = (
    <p>Below are a series of images presented in separate rows. Look at the images carefully. Your task is to try to flip the top row image <strong>horizontally</strong> (left to right, like turning a page in a book). Then, choose among the three possible matches in the bottom rows (labeled “A, B, and C”).</p>
  )

  const info = kind === 'veritical' ? veriticalInfo : horizontalInfo

  return (
    <>
      <Container>
        <h4>Exercise {currentExercise}</h4>
        {info}
        <Row className="mb-3 justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Question className="text-center">
              <h5 className="text-left">{`Set ${currentSet}`}</h5>
              <Image draggable={false} src={`${APIMEDIA_HOST}${questionSrc}`} fluid height="135px" />
            </Question>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {answerCols}
        </Row>
      </Container>
      <FooterButtons 
        buttons={[{ disabled: answer === undefined,label: "Next", action: next, buttonType: "primary" }]} 
      />
    </>
  )
}

export default FtiProgress