import { ExerciseStatus } from "../../constants/exercises"
import { baseUpdateAnswer } from "../common/exerciseHelper"
import { FtiExercise, FtiState, FtiUserAnswer } from "./ftiSlice"

const CHOICES = 'ABC'

export const getChoiceLabel = (index: number): string => CHOICES.charAt(index)

export const updateAnswer = (answer: number, state: FtiState): void => {
  baseUpdateAnswer(
    answer, 
    state,
    (answer, index) => state.userAnswers[index].sets.push(answer)
  )
}

export interface FtiSetResult {
  sets: boolean[]
  percentage: number
}

export interface FtiResults {
  maxSet: number
  results: FtiSetResult[]
}

export const getResults = (status: ExerciseStatus, exercises: FtiExercise[], userAnswers: FtiUserAnswer[]): FtiResults => {
  let maxSet = 0
  let results: FtiSetResult[] = []
  if (status === 'completed' || status === 'check-point') {
    results = userAnswers.map((userAnswer) => {
      const { sets: questionSets } = exercises[userAnswer.index]
      const { sets: answerSets } = userAnswer
      if (questionSets.length > maxSet) {
        maxSet = questionSets.length
      }
      const sets = questionSets.map((set, j) => set.correctAnswer === answerSets[j])
      const correctCount = sets.reduce((acc, set) => set ? acc + 1 : acc , 0)
      const percentage = Math.round(correctCount * 100 / sets.length)
      return { sets, percentage }
    })
    results.reverse()  
  }
  return { maxSet, results }
}