import React, { useState, useEffect } from 'react'
import { Image, Table, Row, Col } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import ExerciseIntro from '../../common/components/ExerciseIntro'
import sample from '../fixtures/images/sample.png'
import FooterButtons from '../../common/components/FooterButtons'
import FtiProgress from './FtiProgress'
import ExerciseComplete from '../../common/components/ExerciseComplete'
import { getCenteredBreathingText, getCognitiveFooterButtons, getCurrent, getCurrentExercise, getCurrentSet, getTotalMinutes, isReadyToStart } from '../../common/exerciseHelper'
import { FtiExercise, FtiExerciseSet, FtiUserAnswer } from '../ftiSlice'
import { getResults } from '../ftiHelper'
import RightWrongIcon from '../../common/components/RightWrongIcon'
import { pages } from '../../../constants/pages'

export interface IFtiExercisesProps {
  status: ExerciseStatus
  exercises: FtiExercise[]
  userAnswers: FtiUserAnswer[]
  startTime?: number
  endTime?: number
  submitAnswer: (answer: number) => void
  next: (repeat?: boolean) => void
  backTo: (route: string) => void
}

const FtiExercises = ({ status, exercises, userAnswers, startTime, endTime, next, submitAnswer, backTo }: IFtiExercisesProps) => {
  const [answer, setAnswer] = useState<number | undefined>(undefined)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    next(true)
  }

  const handleSubmitAnswer = () => {
    if (answer !== undefined) {
      submitAnswer(answer)
      setAnswer(undefined)
      window.scrollTo(0, 0)
    }
  }

  const { currentExercise, currentSet, currentExerciseIndex } = getCurrent(exercises, userAnswers)

  const { kind } = getCurrentExercise(exercises, currentExerciseIndex)

  const { question, answers } = getCurrentSet(exercises, currentExerciseIndex, currentSet) as FtiExerciseSet

  const totalMinutes = getTotalMinutes(startTime, endTime)

  const { maxSet, results } = getResults(status, exercises, userAnswers)

  const completedExercises = currentExercise - 1

  return (
    <>
      <ExerciseIntro status={status}>
        <h4>Instructions</h4>
        <p>Flip the Image is an exercise that  requires you to use your memory skills to “see” the image in your mind’s eye, and then turn or flip it. Find the correctly flipped image from the three choices offered.  The exercises become more challenging the more you do. Here is an example:</p>
        <Row className="justify-content-center mb-3">
          <Col xs={12} md={10} lg={8}>
            <Image draggable={false} fluid src={sample} />
          </Col>
        </Row>
        <p>In this example, flip the image vertically (upside-down). The correct “flipped” image is the middle figure (labeled “B”)</p>
        <p>Have fun!</p>
      </ExerciseIntro>  
      <FtiProgress 
        status={status}
        kind={kind}
        currentExercise={currentExercise}
        currentSet={currentSet}
        questionSrc={question}
        answerSrcs={answers}
        answer={answer}
        pickAnswer={(choice: number) => setAnswer(choice)}
        next={handleSubmitAnswer}
      />
      <ExerciseComplete status={status} category="cognitive" totalMinutes={totalMinutes} completedExercises={completedExercises}>
        <h4>Result</h4>
        <Table striped responsive>
          <thead>
            <tr>
              <th key="empty">&nbsp;</th>
              { Array.from(Array(maxSet).keys()).map(index => (<th key={index}>Set {index + 1}</th>)) }
              <th key="correct">Correct %</th>
            </tr>            
          </thead>
          <tbody>
            { results.map((result, i) => (
              <tr key={`row-${i}`}>
                <td key="ex">Ex.&nbsp;{results.length - i}</td>
                { result.sets.map((set, j) => (<td key={`cell-${i}-${j}`}><RightWrongIcon correct={set} /></td>))}
                <td key="percentage">{result.percentage}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ExerciseComplete>
      <FooterButtons
        extraText={getCenteredBreathingText(status)}
        hidden={status === 'in-progress'}
        buttons={getCognitiveFooterButtons(
          status,
          pages.flipTheImage.title!,
          isReadyToStart(status, exercises),
          backTo,
          handleNext,
          handleRepeat
        )}
      />  
    </>
  )
}

export default FtiExercises